let resumeData = {
  imagebaseurl: "https://github.com/mariustoporascu",
  name: "Marius Toporascu",
  role: "Self-taught developer",
  linkedinId:
    "https://www.linkedin.com/in/marius-alexandru-toporascu-47b539204/",
  roleDescription:
    " My passion is to create web and mobile applications with good security. Advanced in an assortment of technologies, including C#, C++, Java, HTML, JavaScript and CSS. Dedicated to learning additional technologies and coding languages. Equipped with a diverse and promising skill set. Able to effectively self-manage during independent projects, as well as collaborate in a team setting.",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/marius-alexandru-toporascu-47b539204/",
      className: "fa fa-linkedin",
    },
    {
      name: "github",
      url: "https://github.com/mariustoporascu",
      className: "fa fa-github",
    },
  ],
  aboutme:
    "My story starts when I decided to study to become a merchant marine deck officer. Graduated and started sailing across the vast sea. Visited many ports and places around the world, but something just didn’t feel right. After taking everything in consideration and putting all aspects into balance, I decided that this job did not provide me with enough satisfaction anymore. As traveling across the vast sea is not always fun and safe, I wanted to finally try something I always wanted to try. Since I was interested in computers from a young age, I always wanted to progress on the path of Software development but never had the opportunity to do it. Now, after so many years, I finally started on the path of self-taught development. Few months later after lots of practice, studies and hard work, I was able to build websites using technologies such as ASP.NET Core framework and various Android App's in Android Studio. Working as a Software Developer is something I really want to do with passion.",
  address: "mariustoporascu@yahoo.com",
  website: "+40 725 135 824",
  education: [
    {
      UniversityName: "Constanta Maritime University",
      specialization: "Navigation and Naval Transport",
      MonthOfPassing: "Graduated July",
      YearOfPassing: "2012",
      Achievements: "Licenced as Deck Officer for Merchant Ships",
    },
    {
      UniversityName: "Energetical High School",
      specialization: "Technical Electrician",
      MonthOfPassing: "Graduated July",
      YearOfPassing: "2008",
      Achievements: "Licenced to work as Electrician",
    },
  ],
  work: [
    {
      CompanyName: "Encorsa",
      specialization: "Software Developer",
      MonthOfLeaving: "Currently working here",
      YearOfLeaving: "since January 2021",
      Achievements: "",
    },
    {
      CompanyName: "Shipping Industry",
      specialization: "Third and Second Officer",
      MonthOfLeaving: "December 2012",
      YearOfLeaving: "- October 2021",
      Achievements: "",
    },
  ],
  skillsDescription:
    "C#, C++, Java, HTML5, CSS, JavaScript, ASP.Net Core, Xamarin Forms, React.JS, MSSQL, MySQL",
  skills: [
    {
      skillname: "C#",
      rank: 7.5,
    },
    {
      skillname: "C++",
      rank: 7.5,
    },
    {
      skillname: "HTML5",
      rank: 7.5,
    },
    {
      skillname: "CSS",
      rank: 6,
    },
    ,
    {
      skillname: "JavaScript",
      rank: 6,
    },
    ,
    {
      skillname: "ASP.Net Core",
      rank: 7.5,
    },
    ,
    {
      skillname: "Xamarin Forms",
      rank: 6.5,
    },
    ,
    {
      skillname: "React.JS",
      rank: 6,
    },
    ,
    {
      skillname: "MSSQL",
      rank: 6.5,
    },
    ,
    {
      skillname: "MySQL",
      rank: 6.5,
    },
  ],
  portfolio: [
    {
      name: "Contab Helper Platform",
      description: "Document processing platform",
      website: "https://contabhelper.ro",
      imgurl: "images/portfolio/contab.jpg",
    },
    {
      name: "City-Food Mobile App",
      description: "Fast-food Android/IOS App",
      website:
        "https://play.google.com/store/apps/details?id=com.tmiit.cityfood",
      imgurl: "images/portfolio/cityapp.jpg",
    },
    {
      name: "City-Food.ro Website",
      description: "Fast-food website",
      website: "https://city-food.ro",
      imgurl: "images/portfolio/cityfood.jpg",
    },
    {
      name: "Livro Website",
      description: "Website in ASP.Net Core",
      website: "https://livro.azurewebsites.net",
      imgurl: "images/portfolio/livropres.jpg",
    },
    {
      name: "Livro Admin Panel",
      description: "Server in ASP.Net Core",
      website: "https://livromng.azurewebsites.net",
      imgurl: "images/portfolio/livromng.jpg",
    },
    {
      name: "Livro Client App",
      description: "Android/iOS mobile app",
      imgurl: "images/portfolio/livroapp.jpg",
      website: "https://github.com/mariustoporascu/Livro-Mobile-App-Client",
    },
    {
      name: "Livro Manage App",
      description: "Android/iOS mobile app",
      imgurl: "images/portfolio/livroapp2.jpg",
      website: "https://github.com/mariustoporascu/Livro-Mobile-App-Management",
    },
  ],
  testimonials: [
    // {
    //   description: "",
    //   name: "",
    // },
    // {
    //   description: "",
    //   name: "",
    // },
  ],
};

export default resumeData;
